import { useEffect } from "react";
import { Badge } from "@material-ui/core";
import {
  AccountCircle,
  ShoppingCartRounded,
  Info,
  ShoppingBasketRounded,
  GetAppRounded
} from "@material-ui/icons";
import Autocomplete from "./AutoComplete";
import styled from "styled-components";
import DropDownMenu from "./MenuComponents/DropDownMenu";
import CartMenuContent from "./MenuComponents/CartMenuContent";
import AccountMenuContent from "./MenuComponents/AccountMenuContent";
import AboutMenuContent from "./MenuComponents/AboutMenuContent";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cartActions from "../redux/actions/cartActions";
import  useWindowWidthAndHeight  from "../utilities/hooks/useWindowWidthAndHeight";
import MenuBar from "../components/MenuComponents/MenuBar";
import Announcement from "./Announcement";
import { useState } from "react";
//#region STYLES

const StickyContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: sticky;
  background-color:#fff ;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  top: 0px;
  z-index: 10;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  padding-bottom: 0px;
  flex-direction: column;
  justify-content: space-between;
 

  @media only screen and (min-width: 769px) {
    flex-direction: row;
  }
  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
  @media only screen and (min-width: 1200px) {
    flex-direction: row;
  }
`;
//#region Left
const Left = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;
`;

const LogoContainer = styled.div`
  font-size: 60px;
  font-weight: 300;
  color: #333;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const LogoImage = styled.img`
  flex: 1;
  //height: 4.5rem;
  height: ${(props) => (props.isScrolled ? "3rem" : "5.2rem")};
  object-fit: cover;
  margin-top: 0.5rem;
`;
//#endregion
//#region center
const Center = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 769px) {
    margin-bottom:1rem ;
  }
  
`;

const SearchBar = styled.div`
  margin: 5px;
  display: flex;
`;

//#endregion
//#region Right
const Right = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 10px;
`;

const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
`;

const AccountInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.8rem;
  margin-left: 10px;
  letter-spacing: 1px;
  cursor: pointer;
  & a {
    color:black ;
    text-decoration-line:none;
  }
`;
//#endregion
//#endregion
function NavBar(props) {
  const navigate = useNavigate();
  const { width } = useWindowWidthAndHeight();
  const [isScrolled, setIsScrolled] = useState(false);
  const applePlatform = window.navigator.platform.match(/iP(ad|hone)/);
  const androidPlatform = window.navigator.platform.match(
    /Mobile|mini|Fennec|Android|Linux/
  );
  useEffect(() => {
    props.actions.getCartList();
  }, [props.actions]);
  
  useEffect(() => {
    const handleScroll = () => {
    
      if(window.scrollY === 0)
        isScrolled && setIsScrolled(false);
      else 
        !isScrolled && setIsScrolled(true);
    };

    window.addEventListener("scrollend", handleScroll);

    return () => {
      window.removeEventListener("scrollend", handleScroll);
    };
  }, [isScrolled]);
  
  const mobileRender = () => {
    return(
      <Container>
      <Center>
        <Announcement/>
      </Center>
      <Left>
      <LogoContainer
          onClick={(_) => {
            navigate("/");
          }}
        >
          <LogoImage isScrolled={isScrolled} src={`/catalog/Products/logo.png`}></LogoImage>
        </LogoContainer>
        <ButtonSection>
            {/* SHOP ICON*/}
            {props.settings.registeruser && (
              <AccountInfo>
                <DropDownMenu
                  flexDirection="column"
                  icon={<AccountCircle />}
                  text={"ACCOUNT"}
                  contentWidth="11rem"
                  fontSize="0.8rem"
                  fontWeight="400"
                  right="4rem"
                  top={isScrolled ? "6rem" : "7rem" }
                  minHeight="5vh"
                  contentLinks={<AccountMenuContent />}
                />
              </AccountInfo>
            )}
            
            {props.cart.length > 0 && (
               <AccountInfo>
               <DropDownMenu
                 flexDirection="column"
                 icon={
                   <Badge
                     style={{ marginBottom: "0px" }}
                     badgeContent={props.cart !== null && props.cart.length}
                     color="secondary"
                   >
                     <ShoppingCartRounded />
                   </Badge>
                 }
                 text={"BASKET"}
                 contentWidth = {(width <= 648 )?"20rem" : "38rem" }
                 fontSize="0.8rem"
                 fontWeight="400"
                 right="1rem"
                 top={isScrolled ? "6rem" : "7rem" }
                 minHeight="50vh"
                 contentLinks={<CartMenuContent />}
               />
             </AccountInfo>
            )}
          </ButtonSection>
      </Left>
      <Right>
          
      </Right>
      
    </Container>
    )
  }
  const webRender = () => {
    return(
      <Container>
        <Left>
          <Announcement/>
        </Left>
        <Center>
        <LogoContainer
            onClick={(_) => {
              navigate("/");
            }}
          >
            <LogoImage isScrolled={isScrolled} src={`/catalog/Products/logo.png`}></LogoImage>
          </LogoContainer>
        </Center>
        <Right>
            <ButtonSection>
              {/* SHOP ICON*/}
              {props.settings.registeruser && (
                <AccountInfo>
                  <DropDownMenu
                    flexDirection="column"
                    icon={<AccountCircle />}
                    text={"ACCOUNT"}
                    contentWidth="11rem"
                    fontSize="0.8rem"
                    fontWeight="400"
                    right="5rem"
                    top={isScrolled ? "2.5rem" : "4rem" }
                    minHeight="5vh"
                    contentLinks={<AccountMenuContent />}
                  />
                </AccountInfo>
              )}
              <AccountInfo>
                <DropDownMenu
                  flexDirection="column"
                  icon={<Info />}
                  text={"ABOUT"}
                  contentWidth="11rem"
                  fontSize="0.8rem"
                  fontWeight="400"
                  right="1.5rem"
                  top={isScrolled ? "2.5rem" : "4rem" }
                  minHeight="10vh"
                  contentLinks={<AboutMenuContent />}
                />
              </AccountInfo>
              {props.cart.length > 0 && (
                <AccountInfo>
                  <DropDownMenu
                    flexDirection="column"
                    icon={
                      <Badge
                        style={{ marginBottom: "0px" }}
                        badgeContent={props.cart !== null && props.cart.length}
                        color="secondary"
                      >
                        <ShoppingCartRounded />
                      </Badge>
                    }
                    text={"BASKET"}
                    contentWidth="40rem"
                    fontSize="0.8rem"
                    fontWeight="400"
                    right="0.8rem"
                    top={isScrolled ? "2.5rem" : "4rem" }
                    minHeight="50vh"
                    contentLinks={<CartMenuContent />}
                  />
                </AccountInfo>
              )}
            </ButtonSection>
        </Right>
        
      </Container>
    )
  }
  return (
    <StickyContent>
      {width > 768 ? webRender() : mobileRender()}
      <MenuBar/>
      <Center>
        <SearchBar>
          <Autocomplete />
        </SearchBar>
      </Center>
    </StickyContent>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCartList: bindActionCreators(cartActions.getCartList, dispatch),
    },
  };
}
function mapStateToProps(state) {
  return {
    cart: state.cartActionReducer,
    settings: state.settingReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
