import * as actionTypes from "./actionTypes";

export function getCompanyInformationSuccess(companyinformation) {
  return { type: actionTypes.Get_CompanyInformation_Success, payload: companyinformation };
}

export function getCompanyInformation() {
  return function (dispatch) {
    let url = `${process.env.REACT_APP_API}/companyinformation`;
    return fetch(url)
      .then((res) => res.json())
      .then((res) => {
        dispatch(getCompanyInformationSuccess(res.message.data));
      }).catch(err=>{dispatch(getCompanyInformationSuccess({
        maintenancemode: true
        }))});
  };
}
