/* eslint-disable jsx-a11y/no-distracting-elements */
import styled from "styled-components";
import { Info,Call } from "@material-ui/icons";
import { colors } from "../res/values/values";
import { connect } from "react-redux";
const Container = styled.div`
  background-color: white;
  color: ${colors.darkcolor};
  font-size: 14px;
  font-weight: 400;
  padding: 5px 2px; 
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
function Announcement(props) {
  return (
    <Container>
     <Info style={{ paddingRight: "10px" }} />{" "}
      Order by Phone 
      <Call style={{ paddingLeft:"10px" }} />{" "}
      {props.companyInformation ? props.companyInformation.phoneNo : ''}
    </Container>
  );
}
function mapStateToProps(state) {
  return {
    companyInformation: state.companyInformationReducer
  };
}
export default connect(mapStateToProps, null)(Announcement);
